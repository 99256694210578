import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import "./style.scss";

import { MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AuthContext from "../../../../contexts/AuthContext";
import hasPermission from "../../../../utils/hasPermission";
import NoAccessMessage from "../../../noAccessMessage/NoAccessMessage";
import MarketDataMap from "../../../marketDataMap/MarketDataMap";
import MarketDataCard from "../../../marketDataCard/MarketDataCard";
import { Autocomplete } from "@react-google-maps/api";
import settings from "../../../../settings";
import getPostalCodeFromCoordinates from "../../../../utils/getPostalCodeFromCoordinates";
import useGoogleMaps from "../../../../hooks/useGooglleMaps/useGoogleMaps";

const FilterBlock = ({ title, children }) => {
    return (
        <div className="filter-block">
            <div className="filter-block-header">
                <h2>{title}</h2>
            </div>
            {children}
        </div>
    );
};

const columns = [
    { field: "nbr_bedrooms", headerName: "Nbr chambres" },
    { field: "peb", headerName: "Peb" },
    { field: "surface", headerName: "Surface" },
    { field: "rent", headerName: "Loyer" },
    {
        field: "view",
        headerName: "",
        renderCell: (cellValues) => <a>Voir</a>,
        sortable: false,
        width: 100,
    },
];

const good_type = {
    HOUSE: "Maison",
    APARTMENT_BLOCK: "Immeuble de rapport",
    MIXED_USE_BUILDING: "Immeuble mixte",
    APARTMENT: "Appartement",
    GARAGE: "Garage",
    OFFICE: "Bureau",
    COMMERCIAL: "Commerce",
    INDUSTRY: "Industrie",
    OTHER: "Other",
};

const autoSelectGoodTypes = {
    HOUSE: "Maison",
    APARTMENT: "Appartement",
    GARAGE: "Garage",
    INDUSTRY: "Industrie",
};

const good_surface = [
    {
        label: "Toutes",
        value: "0",
    },
    {
        label: "<60 ㎡",
        value: "1",
    },
    {
        label: "60 ㎡ - 80 ㎡",
        value: "2",
    },
    {
        label: "80 ㎡ - 100 ㎡",
        value: "3",
    },
    {
        label: "100 ㎡ - 120 ㎡",
        value: "4",
    },
    {
        label: "120 ㎡ - 140 ㎡",
        value: "5",
    },
    {
        label: "140 ㎡ - 160 ㎡",
        value: "6",
    },
    {
        label: "160 ㎡ - 180 ㎡",
        value: "7",
    },
    {
        label: "180 ㎡ - 200 ㎡",
        value: "8",
    },
    {
        label: ">200 ㎡",
        value: "9",
    },
];

const MarketDataInner = ({ current_property }) => {
    const { user } = useContext(AuthContext);

    const { isGoogleLoaded } = useGoogleMaps();

    const [userCanUseMarketData, setUserCanUseMarketData] = useState(false);

    const type = current_property.property_data.type;
    const initialGoodType = type in autoSelectGoodTypes ? type : "APARTMENT";

    const [filtersState, setFiltersState] = useState({
        goodType: initialGoodType,
        time: 12,
        surface: "0",
    });

    const [isPropertyDetailVisible, setIsPropertyDetailVisible] =
        useState(false);

    const setCurrentCard = useRef(null);

    const [detailCardData, setDetailCardData] = useState({
        headerImageUrl: "",
        title: "",
        description: "",
    });

    const [nbr_bedrooms, setNbr_bedrooms] = useState(
        current_property.property_data.bedroomCount
            ? current_property.property_data.bedroomCount.toString()
            : ""
    );
    const [peb, setPeb] = useState("");
    const [postalCode, setPostalCode] = useState(
        current_property.property_data.location.postalCode
    );
    const [address, setAddress] = useState(
        current_property.property_data.location.postalCode
    );

    const autocompleteRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [marketData, setMarketData] = useState(null);
    const [page, setPage] = useState(0);

    const [coordinates, setCoordinates] = useState({
        lat: current_property.property_data.location.latitude,
        lng: current_property.property_data.location.longitude,
    });

    const safe_data = {
        properties: marketData?.properties ? marketData.properties : [],
        average_rent: marketData?.average_rent ? marketData.average_rent : 0,
        number_of_records: marketData?.number_of_records
            ? marketData.number_of_records
            : 0,
    };

    for (let index = 0; index < safe_data.properties.length; index++) {
        const item = safe_data.properties[index];
        if (item.peb == "unk") {
            item.peb = "-";
        }
    }

    const search = async () => {
        const filters = {
            category: filtersState.goodType,
            nbr_bedrooms:
                nbr_bedrooms.split(",")[0] == "" ? [] : nbr_bedrooms.split(","),
            peb: peb.split(",")[0] == "" ? [] : peb.toUpperCase().split(","),
            postal_code: postalCode,
            surface: filtersState.surface == "0" ? [] : [filtersState.surface],
            time: filtersState.time,
        };

        setLoading(true);
        const data = {};
        const ret = await axios
            .post("https://immovestor.timour.me/api/statistic-data", filters, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                data.average_rent = response.data.average_rent;
                data.number_of_records = response.data.number_of_records;
            })
            .catch((error) => {
                return null;
            });

        const ret2 = await axios
            .post(
                "https://immovestor.timour.me/api/statistic-data/properties",
                filters,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                data.properties = response.data.properties;
            })
            .catch((error) => {
                return null;
            });

        setMarketData(data);

        setLoading(false);
    };

    useEffect(() => {
        search();
    }, []);

    useEffect(() => {
        (async () => {
            setUserCanUseMarketData(hasPermission(user, "read_marketData"));
        })();
    }, [user]);

    const handleClick = (cellData) => {
        const propertyData = cellData.row;

        if (setDetailCardData && setIsPropertyDetailVisible)
            setCurrentCard.current(propertyData.id);
    };

    useEffect(() => {
        search();
    }, [filtersState, postalCode, nbr_bedrooms, peb]);

    const handleKeyDown = (event, setter) => {
        if (event.key === "Enter") {
            setter(event.target.value);
        }
    };

    const onGoogleLoad = useCallback((autocomplete) => {
        autocompleteRef.current = autocomplete;
    }, []);

    const onPlaceChanged = useCallback(async () => {
        if (autocompleteRef.current !== null) {
            const place = autocompleteRef.current.getPlace();
            const { lat, lng } = place.geometry.location;
            const newPostalCode = await getPostalCodeFromCoordinates(
                lat(),
                lng()
            );

            setCoordinates({ lat: lat(), lng: lng() });
            setAddress(place.formatted_address);
            setPostalCode(newPostalCode);
        }
    }, []);

    const MarketDataForm = () => {
        return (
            <div id="market-data-inner">
                <div className="filter-container">
                    <div className="filter-row">
                        <FilterBlock title={"Type de bien*"}>
                            <Select
                                size="small"
                                className="select-filter"
                                labelId="demo-simple-select-label"
                                fullWidth
                                id="demo-simple-select"
                                value={filtersState.goodType}
                                onChange={(e) =>
                                    setFiltersState({
                                        ...filtersState,
                                        goodType: e.target.value,
                                    })
                                }
                            >
                                {Object.keys(good_type).map((elem, id) => (
                                    <MenuItem key={id} value={elem}>
                                        {good_type[elem]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FilterBlock>

                        {isGoogleLoaded ? (
                            <FilterBlock title={"Adresse"}>
                                <Autocomplete
                                    onLoad={onGoogleLoad}
                                    onPlaceChanged={onPlaceChanged}
                                    restrictions={{
                                        country:
                                            settings.googleMapSearchCountries,
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        label="ex: code postal, adresse, ..."
                                        defaultValue={address}
                                    />
                                </Autocomplete>
                            </FilterBlock>
                        ) : null}
                    </div>
                    <div className="filter-row">
                        <FilterBlock title={"Nombre de chambres"}>
                            <TextField
                                size="small"
                                label="ex: 2,4,5"
                                fullWidth
                                defaultValue={nbr_bedrooms}
                                onBlur={(e) => setNbr_bedrooms(e.target.value)}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, setNbr_bedrooms)
                                }
                            />
                        </FilterBlock>

                        <FilterBlock title={"Peb"}>
                            <TextField
                                size="small"
                                label="ex: A++,A+,A"
                                fullWidth
                                defaultValue={peb}
                                onBlur={(e) => setPeb(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e, setPeb)}
                            />
                        </FilterBlock>
                    </div>
                    <div className="filter-row">
                        <FilterBlock title={"Surface"}>
                            <Select
                                size="small"
                                className="select-filter"
                                labelId="demo-simple-select-label"
                                fullWidth
                                id="demo-simple-select"
                                value={filtersState.surface}
                                onChange={(e) =>
                                    setFiltersState({
                                        ...filtersState,
                                        surface: e.target.value,
                                    })
                                }
                            >
                                {good_surface.map((elem, id) => (
                                    <MenuItem key={id} value={elem.value}>
                                        {elem.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FilterBlock>

                        <FilterBlock title={"Intervalle de temps"}>
                            <Select
                                size="small"
                                className="select-filter"
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filtersState.time}
                                onChange={(e) =>
                                    setFiltersState({
                                        ...filtersState,
                                        time: e.target.value,
                                    })
                                }
                            >
                                {[
                                    0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 15, 18, 21,
                                    24, 30, 36,
                                ].map((elem, id) => (
                                    <MenuItem key={id} value={elem}>
                                        {elem} mois
                                    </MenuItem>
                                ))}
                            </Select>
                        </FilterBlock>
                    </div>
                </div>
                <div className="show-data">
                    <div className="data-header">
                        <div className="data-show">
                            <span className="label">Nombre de biens</span>
                            <span className="value">
                                {safe_data.number_of_records}
                            </span>
                        </div>
                        <div className="data-show">
                            <span className="label">Moyenne des loyers</span>
                            <span className="value">
                                {safe_data.average_rent.toFixed(2)}€
                            </span>
                        </div>
                    </div>
                    <div className="data-body">
                        <DataGrid
                            rows={safe_data.properties}
                            columns={columns}
                            onPaginationModelChange={(e) => setPage(e.page)}
                            paginationModel={{ page, pageSize: 9 }}
                            onRowClick={(cellData) => handleClick(cellData)}
                        />
                    </div>
                    <MarketDataCard
                        setCurrentCard={setCurrentCard}
                        properties={safe_data.properties}
                        headerImageUrl={detailCardData.headerImageUrl}
                        title={detailCardData.title}
                        description={detailCardData.description}
                        rent={detailCardData.rent}
                        isVisible={isPropertyDetailVisible}
                        setIsVisible={setIsPropertyDetailVisible}
                    />
                    <MarketDataMap
                        setIsPropertyDetailVisible={setIsPropertyDetailVisible}
                        setDetailCardData={setDetailCardData}
                        setCurrentCard={setCurrentCard}
                        properties={safe_data.properties}
                        coordinates={coordinates}
                        isGoogleLoaded={isGoogleLoaded}
                    />
                </div>
            </div>
        );
    };

    return userCanUseMarketData ? <MarketDataForm /> : <NoAccessMessage />;
};

export default MarketDataInner;
